<template>
  <div class="bg--main">
    <div class="container">
      <div class="header">
        <router-link to="/" class="header__logo"></router-link>
      </div>
      <div class="horoscope">
        <h1 class="horoscope__tit">
            Индивидуальный
        </h1>
        <h2 class="horoscope__tit-small">
          Гороскоп на 2023 год
        </h2>
        <h3 class="horoscope-blocks__desc">
          выберите ваш знак зодиака
        </h3>
      </div>
    </div>
    <footer-block />
    <div class="login">
      <div class="login-block">
        <div class="login-block__buttons">
          <button class="login-block__button login-block__button--active">
            авторизация
          </button>
        </div>
        <form class="form" @submit.prevent="login">
          <div class="form-groups">
            <div class="form-group">
              <div class="form-group__top">
                <label for="email" class="form-group__label">Email</label>
              </div>
              <div class="form-group__block">
                <input id="pass" class="form-group__input" type="email"
                  placeholder="Введите email"
                  required
                  v-model="user.email"
                >
              </div>
            </div>
            <div class="form-group">
              <div class="form-group__top">
                <label for="pass" class="form-group__label">Пароль</label>
              </div>
              <div class="form-group__block">
                <input id="pass" class="form-group__input"
                  :type="isPassword ? 'password' : 'text'"
                  placeholder="Введите пароль"
                  required
                  v-model="user.password"
                >
                <button class="form-group__hide"
                  :class="{'form-group__hide--visible' : isPassword}"
                  @click.prevent="isPassword = !isPassword" />
              </div>
              <span class="error">{{error}}</span>
            </div>
          </div>
          <button class="horoscope__button horoscope__button--form"
            type="submit"
            :disabled="loading"
          >
            {{ loading ? 'Загрузка...' : 'Войти в личный кабинет' }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isPassword: true,
      user: {
        email: null,
        password: null,
      },
      error: null,
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.email) {
      this.user.email = this.$route.query.email;
      this.user.password = this.$route.query.password;
      this.login();
    }
  },
  methods: {
    async login() {
      this.loading = true;
      this.error = '';
      this.user.guide = 2;
      const response = await this.$request.post('/api/guide/login', this.user);
      if (response.status !== 200 || !response.data.success) {
        this.error = 'Неправильный email или пароль';
      } else {
        localStorage.setItem('token', response.data.result.token);
        this.$router.replace('/');
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.bg--main {
  height: 100vh;
  overflow: auto;
}
.error {
  color: #f00;
  font-size: 1.6rem;
  margin-top: 1rem;
  display: block;
}
</style>
